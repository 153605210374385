import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatDate } from './utils';
import { useAppContext } from '../context/AppContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Modalwindow } from './modal';


export function ArticleSnippet({ article, matchedCategory }) {
  const { state } = useAppContext();
  const { articles} = state;
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedArticle, setSelectedArticle] = React.useState(null);
  const navigate = useNavigate();

  const handleShowModal = (article) => {
    setSelectedArticle({ ...article, matchedCategory }); // Attach matchedCategory to the article object
    setModalShow(true);
  };

  const handleCategoryClick = (categorySlug) => {
   
    if (!(categorySlug && articles.length > 0 && articles[0].team_categories && articles[0].team_categories[0])) {
      navigate(`/category/${categorySlug}`);
    }

  };

  return (
    <>
      <Row>
        <Col className="my-6 my-lg-6 article-list">
          <div className="border-2 border-top border-bottom border-primary py-3">
            <p className="article-date">{formatDate(article.published_date)}</p>
            <p className="h3 fw-bold">{article.title}</p>

            {/* Show button only for matched category */}
            {matchedCategory && (
              <Button
                size="xs"
                variant="outline-danger"
                className="categories-btn mt-4 mb-2"
                key={matchedCategory.category_slug}
                onClick={() => handleCategoryClick(matchedCategory.category_slug)}
              >
                {matchedCategory.category_name}
              </Button>
            )}
          </div>

          <div className="button-group justify-content-end my-3">
            <Button variant="link" size="sm" className="article-link">
              <Link to={`${article.link}`} target="_blank">
                <span>Ler Artigo</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="icon icon-tabler icon-tabler-external-link"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  strokeWidth="2"
                  stroke="#2c3e50"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
                  <path d="M11 13l9 -9" />
                  <path d="M15 4h5v5" />
                </svg>
              </Link>
            </Button>
            <Button variant="primary" size="sm" onClick={() => handleShowModal(article)}>
              Saber mais
            </Button>
          </div>
        </Col>
      </Row>

      {selectedArticle && (
        <Modalwindow
          show={modalShow}
          onHide={() => setModalShow(false)}
          article={selectedArticle}
        />
      )}
    </>
  );
}

export default ArticleSnippet;