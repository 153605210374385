import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { formatDate } from './utils';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export function Modalwindow({ show, onHide, article }) {
  const [listClass, setListClass] = useState('single-column');

  useEffect(() => {
      if (article && article.authors && article.authors.length >= 5) {
          setListClass('two-columns');
      } else {
          setListClass('single-column');
      }
  }, [article]);

  if (!article) {
    return null; // Render nothing if no article is provided
  }

  const mainColSize = listClass === 'single-column' ? 8 : 7;
  const authorColSize = listClass === 'single-column' ? 4 : 5;

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      className='modal-article '
      fullscreen='lg-down'
    >
      <Modal.Header className='border-bottom-0 mb-0 pb-2' closeButton>

      </Modal.Header>
      <Modal.Body className='mt-0 pt-0'>
      <Container>
          <Row>
            <Col className="my-4 article-list col">
              <div className="border-2 border-top border-bottom border-primary py-3">
                <p className="article-date">{formatDate(article.published_date)}</p>
                <h3 className="h3 fw-bold">
                  {article.title}
                </h3>
                <div className='button-group mt-1 mb-n1 justify-content-between'>
                  <Button variant='link' size='sm' className='article-link px-0'>
                    <Link className='' to={`${article.link}`} target='_blank'>
                      <span>Ler Artigo</span>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-external-link" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6" />
                      <path d="M11 13l9 -9" />
                      <path d="M15 4h5v5" />
                    </svg>
                    </Link>
                  </Button>
                  {article.matchedCategory ? (
  <Button
    size="xs"
    variant="outline-danger"
    className="categories-btn nohover"
    aria-label={`Category ${article.matchedCategory.category_name}`}
  >
    {article.matchedCategory.category_name}
  </Button>
) : article.team_categories.length !== 0 ? (
  <Button
    size="xs"
    variant="outline-danger"
    className="categories-btn nohover"
    aria-label={`Category ${article.team_categories[0].category_name}`}
  >
    {article.team_categories[0].category_name}
  </Button>
) : ""}

                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col md={mainColSize}>
              <h2 className='fw-bold'>Main Takeaways:</h2>
              {article.takeaways === "" || article.takeaways === null ? <p>No takeaways available.</p> : <span className='mb-4 d-inline-block'>{article.takeaways}</span>}
              <h2 className='fw-bold pt-2'>Discovery Date:
              {article.discovery_date === "" || article.discovery_date === null ? <span>No discovery date available.</span> : <span> {formatDate(article.discovery_date)}</span>}</h2>
              <h2 className='fw-bold pt-2'>Published Date:
              {article.published_date === "" || article.published_date === null ? <span>No published date available</span> : <span> {formatDate(article.published_date)}</span>}</h2>
              <h2 className='fw-bold pt-2'>Publisher:
              {article.publisher === "" || article.publisher === null ? <span>No publisher information available</span> : <span> {article.publisher}</span>}</h2>
            </Col>
            <Col md={authorColSize}>
              <h2 className='fw-bold pt-2'>Authors:</h2>
              {article.authors ? (
                <ul className={`authors list-unstyled align-items-start ${listClass}`}>
                  {article.authors.map(author => (
                    <li key={author.author_id}>
                      {author.given_name} {author.family_name} {author.ORCID && <a href={author.ORCID}>(ORCID)</a>}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No authors available.</p>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default Modalwindow;
