import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ArticleSnippet from './ArticleSnippet';

const ArticlesGrid = ({ articles, matchedCategory }) => {
  // Filter articles to only show those in matchedCategory (if available)
  const filteredArticles = matchedCategory
    ? articles.filter(article =>
        article.team_categories.some(category => category.category_slug === matchedCategory.category_slug)
      )
    : articles;

  return (
    <div>
      {filteredArticles.map((article, index) => (
        index % 2 === 0 ? (
          <Row key={index} className="my-4">
            <Col md={6} className='article-column'>
              <ArticleSnippet article={article} matchedCategory={matchedCategory} />
            </Col>
            {index + 1 < filteredArticles.length && (
              <Col md={6} className='article-column'>
                <ArticleSnippet article={filteredArticles[index + 1]} matchedCategory={matchedCategory} />
              </Col>
            )}
          </Row>
        ) : null
      ))}
    </div>
  );
};

export default ArticlesGrid;
