import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import ArticlesGrid from '../components/ArticlesGrid';
import PaginationComponent from '../components/Pagination';
import { formatDate } from '../components/utils';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Categories from '../components/CategoriesList';
import Sources from '../components/Sources';
import BackButton from '../components/BackButton';

export function ArticleList({ displayAsList }) {
  const { state } = useAppContext();
  const { articles, subjects, loading, error, totalArticleCount } = state;
  const { pageNumber, categorySlug } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState(pageNumber ? parseInt(pageNumber, 10) : 1);

  useEffect(() => {
    setPage(pageNumber ? parseInt(pageNumber, 10) : 1);
  }, [pageNumber]);

  useEffect(() => {
    if (!loading) {
      window.scrollTo(0, 0);
    }
  }, [page, loading]);

  const handleCategoryChange = (slug) => {
    setPage(1);
    navigate(slug ? `/category/${slug}` : `/`);
  };

  const getSubjectData = () => {
    const subject_name = subjects.length > 0 ? subjects[0].subject_name : '';
    let matchedCategory = null;
  
    if (categorySlug && articles.length > 0 && articles[0].team_categories) {
      matchedCategory = articles[0].team_categories.find(category => category.category_slug === categorySlug);
    }
  
    const pageTitle = matchedCategory
      ? `"${matchedCategory.category_name}" em ${subject_name}`
      : `Investigação atual relevante em ${subject_name}`;
  
    return { pageTitle, matchedCategory };
  };
  
  const { pageTitle, matchedCategory } = getSubjectData();

  if (loading) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const articleContent = displayAsList ? (
    <ol start={(page - 1) * 10 + 1}>
      {articles.map((article) => (
        <li key={article.article_id}>
          <Link to={`/articles/${article.article_id}`} dangerouslySetInnerHTML={{ __html: article.title }} />
          {formatDate(article.published_date)}
        </li>
      ))}
    </ol>
  ) : (
    <ArticlesGrid articles={articles} matchedCategory={matchedCategory} />
  );

  const totalPages = Math.ceil(totalArticleCount / 10); // Update as per your pagination logic
  const currentPagePath = categorySlug ? `category/${categorySlug}/page` : 'page';

  return (
    <div className="fade-in">
      <Container>
        <Row className="mt-6 mb-4">
          <Col md={8}>
            {(categorySlug && articles.length > 0 && articles[0].team_categories && articles[0].team_categories[0]) && (
              <BackButton />
            )}
            <h1 className="pagetitle">
              <div dangerouslySetInnerHTML={{ __html: pageTitle }} />
              {""}
            </h1>
          </Col>
        </Row>
      </Container>
      {!(categorySlug && articles.length > 0 && articles[0].team_categories && articles[0].team_categories[0]) && (
        <Categories onCategoryChange={handleCategoryChange} />
      )}
      <div className="full-width-background">
        <Container className="pt-3">
          <Row className="mb-6">
            <Col>
              {articles.length > 0 ? articleContent : <p>Nenhum artigo encontrado.</p>}
            </Col>
          </Row>
          {totalPages > 1 && (
            <Row>
              <Col className="d-flex flex-wrap justify-content-center mb-8 mt-2">
                <PaginationComponent page={page} setPage={setPage} last_page={totalPages} page_path={currentPagePath} />
              </Col>
            </Row>
          )}
        </Container>
      </div>
       <Sources /> {/*Use the Sources component */}
    </div>
  );
}

export default ArticleList;
